import React from "react"
import { graphql, Link } from "gatsby"
import get from "lodash.get"
import moment from "moment"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav";
import { rhythm } from "../utils/typography"


class NowPostTemplate extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = get(data, "site.siteMetadata.title")
    const frontmatter = get(data, "markdownRemark.frontmatter")
    const dateString = moment(frontmatter.date).format("MMM Do [']YY");
    const { previous, next } = this.props.pageContext
    return (
      <Layout location={this.props.location} title={siteTitle}>
          <SEO title="Akaash's Internet Domicile" />
          <Nav />
          <h1>My now page as of {dateString}</h1>
          <p>
              It's inspired by Derek Sivers'<a href={`https://nownownow.com/about`} style={{ marginLeft: `4px` }}>nownownow</a>&nbsp;&nbsp;project,
              and is meant to capture a point-in-time snapshot of what I was upto at the time. I've written about how I built and maintain it
              <a href={`/gatsby-now-page/`} style={{ marginLeft: `4px` }}>here</a>.
          </p>
          <h3>Listening</h3>
          {frontmatter.music && <div style={{ marginBottom: rhythm(1/4)}}>
              <strong>Music</strong>: <a href={frontmatter.musiclink}>{frontmatter.music}</a><br/>
          </div>}
          {frontmatter.podcast && <div>
              <strong>Podcast</strong>: <a href={frontmatter.podcastlink}>{frontmatter.podcast}</a><br/>
          </div>}
          <h3 style={{ marginTop: rhythm(2)}}>Reading</h3>
          {frontmatter.book && <div style={{ marginBottom: rhythm(1/4)}}>
              <strong>Book</strong>: <a href={frontmatter.booklink}>{frontmatter.book}</a><br/>
          </div>}
          {frontmatter.article && <div>
              <strong>Article</strong>: <a href={frontmatter.articlelink}>{frontmatter.article}</a><br/>

          </div>}
          <h3 style={{ marginTop: rhythm(2)}}>Watching</h3>
          {frontmatter.show && <div style={{ marginBottom: rhythm(1/4)}}>
              <strong>TV</strong>: <a href={frontmatter.showlink}>{frontmatter.show}</a><br/>
          </div>}
          {frontmatter.movie && <div>
              <strong>Movie</strong>: <a href={frontmatter.movielink}>{frontmatter.movie}</a><br/>
          </div>}
          <div style={{ marginBottom: rhythm(2)}}/>

          <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`/now${previous.fields.slug}`} rel="prev">
                ← {previous.fields.slug}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/now${next.fields.slug}`} rel="next">
                {next.fields.slug} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default NowPostTemplate

export const pageQuery = graphql`
    query NowPostBySlug($slug: String!){
      site {
        siteMetadata {
            title
        }
      }
      markdownRemark(fields: { slug: { eq: $slug } }) {
        id
        frontmatter {
              date
              title
              music
              musiclink
              podcast
              podcastlink
              book
              booklink
              article
              articlelink
              tweet
              quote
              learning 
              movie
              show
        }
      }
    }
`